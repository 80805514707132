<template>
  <div id="events-preview">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="ResultFlag">
          <v-row wrap>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Submitted Date</h5>
              <p class="preview-content">{{ row.SubmittedDate }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">From Zone</h5>
              <p class="preview-content">{{ row.FromZoneName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">From Lom</h5>
              <p class="preview-content">{{ row.FromLomName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">From Year & Half</h5>
              <p class="preview-content">{{ row.FromYearHalf }}</p>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">To Zone</h5>
              <p class="preview-content">{{ row.ToZoneName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">To Lom</h5>
              <p class="preview-content">{{ row.ToLomName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Member Details</h5>
              <p class="preview-content">{{ row.MemberName }}</p>
              <p class="preview-content">{{ row.MembershipId }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Contact Details</h5>
              <p class="preview-content">{{ row.EmailId }}</p>
              <p class="preview-content">{{ row.MobileNo }}</p>
            </v-col>
          </v-row>
          <hr />
          <br />
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12" align="center">
              <h4>Lom Payment Details</h4>
            </v-col>
            <v-col align="center" cols="12" md="12">
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :headers="tableColumns"
                :items="tableData"
                :show-select="tableOptions.ShowSelectFlag"
                :item-key="tableOptions.ItemKey"
                :single-select="tableOptions.SingleSelectFlag"
                :items-per-page="tableOptions.ItemsPerPage"
                :footer-props="tableOptions.FooterProps"
              >
              </v-data-table>
            </v-col>
          </v-row>
          <hr />
          <br />
          <v-container v-if="!previewRecordFlag && row.ApprovalFlag">
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12" align="center">
                <h4>For administrative purpose only</h4>
              </v-col>
            </v-row>
            <v-form
              ref="form1"
              v-model="valid1"
              lazy-validation
              v-on:submit.prevent="searchForm"
            >
              <v-row wrap>
                <v-col cols="12" sm="12" lg="12" md="12">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Approval Description
                    </h6>
                  </label>
                  <v-text-field
                    v-model="ApprovalDescription"
                    :rules="ApprovalDescriptionRules"
                    hint="Enter the Description"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    @click.prevent="confirmAlert(1)"
                    :loading="ApproveLoadingFlag"
                    color="success"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Approve
                  </v-btn>
                  <v-btn
                    @click.prevent="confirmAlert(2)"
                    :loading="ApproveRejectLoadingFlag"
                    color="warning"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Reject
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    color="primary"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container v-if="!previewRecordFlag && row.ConfirmationFlag">
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12" align="center">
                <h4>For Lom purpose only</h4>
              </v-col>
            </v-row>
            <v-form
              ref="form2"
              v-model="valid2"
              lazy-validation
              v-on:submit.prevent="confirmAlert"
            >
              <v-row wrap>
                <v-col cols="12" sm="12" lg="12" md="12">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Confirmation
                      Description
                    </h6>
                  </label>
                  <v-text-field
                    v-model="ConfirmationDescription"
                    :rules="ConfirmationDescriptionRules"
                    hint="Enter the Description"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    @click.prevent="confirmAlert(3)"
                    :loading="ConfirmationLoadingFlag"
                    color="success"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Confirm
                  </v-btn>
                  <v-btn
                    @click.prevent="confirmAlert(4)"
                    :loading="ConfirmationRejectLoadingFlag"
                    color="warning"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Reject
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    color="primary"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="previewRecordFlag">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordFlag: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      valid1: false,
      valid2: false,
      ProgressLoadingFlag: false,
      ResultFlag: false,
      LoadingFlag: false,
      row: {},
      PaymentDetails: {},
      selected: [],

      ApprovalDescription: "",
      ApprovalDescriptionRules: [(v) => !!v || "Description is required"],

      ConfirmationDescription: "",
      ConfirmationDescriptionRules: [(v) => !!v || "Description is required"],

      ApproveLoadingFlag: false,
      ApproveRejectLoadingFlag: false,
      ConfirmationLoadingFlag: false,
      ConfirmationRejectLoadingFlag: false,

      ZoneCode: "",
      LomCode: "",

      tableColumns: [],
      tableOptions: [],
      tableData: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var MemberTransferId = this.recordData.MemberTransferId;
      var MTWaitListId = this.recordData.MTWaitListId;
      var MemberId = this.recordData.MemberId;
      var FromLomCode = this.recordData.FromLomCode;
      var ToLomCode = this.recordData.ToLomCode;
      var UserInterface = MemberTransferId > 0 ? 2 : 1;
      console.log(
        "MemberTransferId=" +
          MemberTransferId +
          ", MTWaitListId=" +
          MTWaitListId +
          ", MemberId=" +
          MemberId +
          ", FromLomCode=" +
          FromLomCode +
          ", ToLomCode=" +
          ToLomCode+
          ", UserInterface=" +
          UserInterface
      );

      if (UserInterface != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/transfer/show";
        var upload = {
          UserInterface: UserInterface,
          MemberTransferId: MemberTransferId,
          MTWaitListId: MTWaitListId,
          MemberId: MemberId,
          FromLomCode: FromLomCode,
          ToLomCode: ToLomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.row = {};
        this.ResultFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.Documentpath = "";

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.ResultFlag = flag;

            var PaymentDetails = records.PaymentDetails;
            console.log("PaymentDetails=" + JSON.stringify(PaymentDetails));

            if (flag == 1) {
              thisIns.row = records;
              console.log("records=" + JSON.stringify(records));
              thisIns.tableColumns = PaymentDetails.TableHeader;
              thisIns.tableData = PaymentDetails.TableData;
              thisIns.tableOptions = PaymentDetails.TableOptions;
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Your session is expired. Kindly login again. ";
        console.log("error=" + message);
      }
    },
    confirmAlert(status) {
      console.log("confirmAlert is called");
      console.log({ status });
      var htmlTxt = "";

      var validate =
        status == 1 || status == 2
          ? this.$refs.form1.validate()
          : this.$refs.form2.validate();
      console.log("validate=" + validate);

      if (validate) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            switch (status) {
              case 1:
                this.approvalSubmitForm(1);

                break;

              case 2:
                this.approvalSubmitForm(2);

                break;

              case 3:
                this.confirmationSubmitForm(1);

                break;

              case 4:
                this.confirmationSubmitForm(2);

                break;

              default:
                break;
            }
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      }
    },
    confirmationSubmitForm(status) {
      console.log("confirmationSubmitForm is called");

      console.log({ status });

      this.ProgressLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/transfer/confirmation";
      var upload = {
        UserInterface: status,
        MTWaitListId: this.row.MTWaitListId,
        FromLomCode: this.row.FromLomCode,
        ToLomCode: this.LomCode,
        Description: this.ConfirmationDescription,
        MemberId: this.row.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var table = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.ProgressLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$emit("hideEditRecordPrompt");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
          thisIns.ProgressLoadingFlag = true;
        });
    },
    approvalSubmitForm(status) {
      console.log("approvalSubmitForm is called");

      console.log({ status });

      this.ProgressLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/transfer/approved";
      var upload = {
        UserInterface: status,
        MTWaitListId: this.row.MTWaitListId,
        FromLomCode: this.row.FromLomCode,
        ToLomCode: this.row.ToLomCode,
        Description: this.ApprovalDescription,
        MemberId: this.row.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var table = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.ProgressLoadingFlag = false;
          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$emit("hideEditRecordPrompt");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
          thisIns.ProgressLoadingFlag = true;
        });
    },
  },
  beforeMount() {
    var ZoneCode = this.$session.get("ZoneId");
    ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
    console.log({ ZoneCode });
    this.ZoneCode = ZoneCode;

    var LomCode = this.$session.get("LomId");
    LomCode = LomCode == (null || undefined) ? "" : LomCode;
    console.log({ LomCode });
    this.LomCode = LomCode;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#events-preview {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>
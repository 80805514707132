<template>
  <div id="member-transfer-conformation">
    <v-container class="py-0">
      <v-speed-dial
        v-if="FloatingButtonFlag"
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn
          fab
          dark
          small
          color="indigo"
          @click.prevent="addRecordPrompt = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>

    <v-dialog v-model="previewRecordPrompt" persistent max-width="75%">
      <members-transfer-preview
        :pageTitle="previewRecordPageTitle"
        :previewRecordFlag="previewRecordFlag"
        :recordData="selectedData"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        v-if="previewRecordPrompt"
      ></members-transfer-preview>
    </v-dialog>

    <v-card>
      <v-card-title class="text-h5">
        <h2>
          {{ PageTitle }}
        </h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              @click.prevent="refreshPageData"
              color="#EDBE38"
              elevation="30"
              shaped
              class="font-size-h6 fsize-3 mr-3 my-3 white--text"
            >
              <v-icon dark> mdi-refresh </v-icon> &nbsp; Refresh
            </v-btn>
          </v-col>
        </v-row>

        <br />
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container class="notes">
          <p>Important notes:</p>
          <ol>
            <li>
              The first tab shows the list of transfer request for your confirmation; Request of members willing to transfer to your LO.
            </li>
            <li>
              The second tab shows the status of the members transferred from your LO.
            </li>
          </ol>
          <p>Status meaning:</p>
          <ol>
            <li>
              Pending for confirmation: The first LO president has initiated the request and the confirmation from the second LO President is pending.
            </li>
            <li>
              Pending for approval: Both the Presidents have confirmed the transfer request and the approval of NHQ is pending.
            </li>
            <li>
              Approved: The final approval is received from the NHQ and the member is transferred successfully.
            </li>
            <li>
              Not approved: The transfer request is not approved by the other LO President or the NHQ; Refer the approval description for reason.
            </li>
          </ol>
        </v-container>

        <br />

        <v-tabs
          v-model="tab"
          background-color="#8950FC"
          centered
          dark
          icons-and-text
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1" :disabled="TabDisable1">
            pending For Confirmation
            <v-icon>mdi-account-tie</v-icon>
          </v-tab>

          <v-tab href="#tab-2" :disabled="TabDisable2">
            Transfer List
            <v-icon>mdi-account-group</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card flat>
              <p></p>
              <v-container class="py-0" v-if="tableData.length == 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>No members found.</h4>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="py-0" v-if="tableData.length > 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3>{{ tableData.length }} members found</h3>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search records here"
                      single-line
                      hide-details
                    ></v-text-field>
                    <br />
                    <v-data-table
                      class="elevation-1"
                      v-model="selected"
                      :headers="tableColumns"
                      :items="tableData"
                      :search="search"
                      :loading="TableLoadingFlag"
                      :show-select="tableOptions.ShowSelectFlag"
                      :item-key="tableOptions.ItemKey"
                      :single-select="tableOptions.SingleSelectFlag"
                      :items-per-page="tableOptions.ItemsPerPage"
                      :footer-props="tableOptions.FooterProps"
                    >
                      <template v-slot:item.ActiveStatusTxt="{ item }">
                        <v-chip
                          :color="getActiveStatusColor(item.ActiveStatusTxt)"
                          draggable
                          dark
                          >{{ item.StatusTxt }}</v-chip
                        >
                      </template>
                      <template v-slot:item.MemberImagePath="{ item }">
                        <img
                          width="100"
                          height="100"
                          :src="item.MemberImagePath"
                        />
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom v-if="item.PreviewFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="blue"
                              @click="previewData(item, 1, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-file-search
                            </v-icon>
                          </template>
                          <span> Preview </span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.EditFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="success"
                              @click="previewData(item, 2, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-shield-check
                            </v-icon>
                          </template>
                          <span> Confirmation </span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card flat>
              <p></p>
              <v-container class="py-0" v-if="tableData1.length == 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>No members found.</h4>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="py-0" v-if="tableData1.length > 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3>{{ tableData1.length }} members found</h3>
                  </v-col>
                </v-row>
                <v-row wrap v-if="tableData1.length > 0">
                  <v-col align="center" cols="12" md="12">
                    <v-text-field
                      v-model="search1"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                    <br />
                    <v-data-table
                      class="elevation-1"
                      v-model="selected1"
                      :headers="tableColumns1"
                      :items="tableData1"
                      :loading="TableLoadingFlag1"
                      :search="search1"
                      :show-select="tableOptions1.ShowSelectFlag"
                      :item-key="tableOptions1.ItemKey"
                      :single-select="tableOptions1.SingleSelectFlag"
                      :items-per-page="tableOptions1.ItemsPerPage"
                      :footer-props="tableOptions1.FooterProps"
                    >
                      <template v-slot:item.ActiveStatusTxt="{ item }">
                        <v-chip
                          :color="getActiveStatusColor(item.ActiveStatusTxt)"
                          draggable
                          dark
                          >{{ item.StatusTxt }}</v-chip
                        >
                      </template>
                      <template v-slot:item.ProfilePic="{ item }">
                        <img width="100" height="100" :src="item.ProfilePic" />
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom v-if="item.PreviewFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="blue"
                              @click="previewData(item, 1, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-file-search
                            </v-icon>
                          </template>
                          <span> Preview </span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.EditFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="primary"
                              @click="previewData(item, 2, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-file-document-edit
                            </v-icon>
                          </template>
                          <span> Edit </span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import MembersTransferPreview from "@/view/pages/erp/member-transfer/MembersTransferPreview.vue";

export default {
  mixins: [common],
  components: {
    MembersTransferPreview,
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      FloatingButtonFlag: false,

      previewRecordPrompt: false,
      previewRecordPageTitle: "",

      tab: null,

      TableLoadingFlag: false,
      tableColumns: [],
      tableOptions: [],
      tableData: [],
      selected: [],
      search: "",

      TableLoadingFlag1: false,
      tableColumns1: [],
      tableOptions1: [],
      tableData1: [],
      selected1: [],
      search1: "",

      TabDisable1: false,
      TabDisable2: false,

      CurrentYearId: 0,

      zone: {},
      lom: {},

      ZoneCode: "",

      LomCode: "",

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log({ ZoneCode });
        this.ZoneCode = ZoneCode;

        var LomCode = this.$session.get("LomId");
        LomCode = LomCode == (null || undefined) ? "" : LomCode;
        console.log({ LomCode });
        this.LomCode = LomCode;

        this.StartupLoadingFlag = false;

        this.searchForm();
      }
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form1.validate()) {
        this.snackbar = true;
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.StartupLoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "members_transfer",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    searchForm() {
      console.log("searchForm is called");
      this.getConfirmationMembersList();
      this.getAllTransferList();
    },
    getConfirmationMembersList() {
      console.log("getConfirmationMembersList is called");

      this.LoadingFlag = true;
      this.TableLoadingFlag = true;
      this.tableData = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/transfer/list";
      var upload = {
        UserInterface: 2,
        YearCode: this.JciYearCode,
        ZoneCode: this.ZoneCode,
        LomCode: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.TableLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions = options;

          if (flag == 1) {
            thisIns.tableColumns = records.TableHeader;
            thisIns.tableData = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.TableLoadingFlag = false;
          thisIns.tableData = [];
        });
    },
    getAllTransferList() {
      console.log("getAllTransferList is called");

      this.LoadingFlag = true;
      this.TableLoadingFlag1 = true;
      this.tableData1 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/transfer/list";
      var upload = {
        UserInterface: 1,
        YearCode: this.JciYearCode,
        ZoneCode: this.ZoneCode,
        LomCode: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.TableLoadingFlag1 = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions1 = options;

          if (flag == 1) {
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableData1 = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.TableLoadingFlag = false;
          thisIns.tableData = [];
        });
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr, type) {
      console.log("previewData called");
      console.log("type=" + type);
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.previewRecordFlag = type == 1 ? true : false;
        this.previewRecordPageTitle =
          type == 1 ? "Member Transfer Preview" : "Confirm Member Transfer";
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.previewRecordPrompt = false;
      this.searchForm();
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#member-transfer-conformation {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
  .notes {
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
  }
}
</style>